import $ from 'jquery'
import { loadReportParameters } from '../shared/report-parameters'
import ScheduleHelpers from '../shared/schedule-helpers'

require('jquery-ui');
require('../../js/plugins/multiSelect');
require('../../js/plugins/jquery-timepicker');

const controlOptions = {
  datepickers: false
};
let refreshNextRunTime = ScheduleHelpers.refreshNextRunTime();
let $reportSelector
let $frequencySelector
let $monthlyOptions
let $weeklyOptions
let $dailyOptions
let $advancedOptions
let $monthlyDateRangeSelector
let $monthlyDaySelector
let $weeklyDateRangeSelector
let $weeklyDaySelector
let $dailyDateRangeSelector
let $weeklyPeriodSelector
let $reportTime

const weekdayLookupMap = new Map([
  ['1', 'mon'],
  ['2', 'tue'],
  ['3', 'wed'],
  ['4', 'thu'],
  ['5', 'fri'],
  ['6', 'sat'],
  ['7', 'sun'],
]);

$(() => {
  $reportSelector = $('select#report_selector');
  $frequencySelector = $('select#frequency')

  $monthlyOptions = $('div#monthly_schedule')
  $weeklyOptions = $('div#weekly_schedule')
  $dailyOptions = $('div#daily_schedule')
  $advancedOptions = $('div#advanced_schedule')

  $monthlyDateRangeSelector = $monthlyOptions.find('select.date_range_selector')
  $monthlyDaySelector = $monthlyOptions.find('select#schedule_day')
  $weeklyDateRangeSelector = $weeklyOptions.find('select.date_range_selector')
  $weeklyPeriodSelector = $weeklyOptions.find('select#schedule_period')
  $weeklyDaySelector = $weeklyOptions.find('select#schedule_day')
  $dailyDateRangeSelector = $dailyOptions.find('select.date_range_selector')
  $reportTime = $('input#report_time')

  $('form').on('submit', () => {
    const fields = ['date_from', 'date_to']
    $('div#parameters_region').find('input,select,textarea').each((i, el) => {
      if (!fields.includes(el.name) && el.name !== '') {
        let name = el.name
        if (name.endsWith('[]')) {
          name = name.substring(0, name.length - 2)
        }

        fields.push(name)
      }
    })

    $('input#fields').val(fields.join(','))
  })

  $reportSelector.change((e) => {
    const report = $(e.target).val()
    if (report !== '') {
      loadReportParameters(report, controlOptions)
    } else {
      $('div#parameters_region').empty()
      $('button#generate_report').prop('disabled', true)
    }
  });

  $frequencySelector.on('change', (e) => {
    $monthlyOptions.hide();
    $weeklyOptions.hide();
    $dailyOptions.hide();
    $advancedOptions.hide();

    switch ($(e.target).val()) {
      case 'Monthly':
        $monthlyOptions.show()
        $monthlyDateRangeSelector.val($monthlyDateRangeSelector.val() === '' ? $monthlyDateRangeSelector.val() : -1)
        break
      case 'Weekly':
        $weeklyOptions.show()
        $weeklyDateRangeSelector.val($weeklyDateRangeSelector.val() === '' ? $weeklyDateRangeSelector.val() : -1)
        break
      case 'Daily':
        $dailyOptions.show()
        $dailyDateRangeSelector.val($dailyDateRangeSelector.val() === '' ? $dailyDateRangeSelector.val() : -1)
        break
      case 'Advanced':
        $advancedOptions.show();
        break;
    }
    refreshDateToFromValues($(e.target).val());
  });

  $('input.timepicker').timepicker({
    'scrollDefault': 'now',
    'timeFormat': 'H:i',
    'step': 5,
    'className': 'form-timepicker',
  })

  $('input#dtstart').datepicker({
    dateFormat: 'dd-mm-yy',
    showButtonPanel: true,
    changeYear: true,
    changeMonth: true,
    minDate: '01-12-2021'
  })

  const initialHour = $('input#byhour').val().padStart(2, '0'),
    initialMinute = $('input#byminute').val().padStart(2, '0');

  $reportTime.val(`${initialHour}:${initialMinute}`);

  $reportTime.change((e) => {
    const timeParts = $(e.target).val().split(':')
    $('input#byhour').val(Number.parseInt(timeParts[0]))
    $('input#byminute').val(Number.parseInt(timeParts[1]))
    refreshNextRunTime()
  })

  $('div#schedule').find('input:not(.timepicker),select').change(refreshNextRunTime)
  $frequencySelector.trigger('change');
  $reportTime.trigger('change');

  $dailyDateRangeSelector.change(getDailyDateFromValues)
  $weeklyDateRangeSelector.change(getWeeklyDateFromValues)
  $weeklyPeriodSelector.change(getWeeklyDateFromValues)
  $weeklyDaySelector.change(getWeeklyRunDay)
  $monthlyDateRangeSelector.change(getMonthlyDateFromValues)
  $monthlyDaySelector.change(getMonthlyRunDay)

  if ($reportSelector.val() !== '') {
    $('input#date_from').on('change', reloadSimplifiedScheduleOptions)
    loadReportParameters($reportSelector.val(), controlOptions, reloadSimplifiedScheduleOptions)
  }

  $('div.boxout li').click((e) => {
    const $li = $(e.target)

    const func = $li.data('schedule')
    try {
      ScheduleHelpers[func]()
      refreshNextRunTime()  // refreshNextRunTime
    } catch (e) {
      console.log(e.toLocaleString())
    }
  })
})
function getMonthlyDateFromValues() {
  let date_from, date_to
  const days = Number.parseInt($monthlyDateRangeSelector.val())
  if (days !== 0) {
    date_from = `midnight ${days} month`
    date_to = `midnight ${days + 1} month`
  } else {
    date_from = 'midnight first day of this month'
    date_to = 'midnight first day of next month'
  }

  $('#date_from').val(date_from)
  $('#date_to').val(date_to)
  refreshNextRunTime()
}

function getMonthlyRunDay() {
  const $bySetPosition = $('input#bysetpos')
  const $weeklyCheckboxes = $('p.frequency_weekly input[type=checkbox]')
  const $monthlyCheckboxes = $('p.frequency_monthly input[type=checkbox]')
  const dayOfMonth = $monthlyDaySelector.val();

  // Reset everything
  $bySetPosition.val('')
  $weeklyCheckboxes.val([])
  $monthlyCheckboxes.val([])

  if (dayOfMonth > 0) {     // Standard Day of Month
    $monthlyCheckboxes.val([dayOfMonth])

  } else if (dayOfMonth === '-1') { // Last Day of Month
    $monthlyCheckboxes.val([28,29,30,31])
    $bySetPosition.val(-1)

  } else if (dayOfMonth === '-2') { // First Weekday of the month
    $weeklyCheckboxes.val(['MO','TU','WE','TH','FR'])
    $bySetPosition.val(1)

  } else if (dayOfMonth === '-3') { // Last Weekday of the month
    $monthlyCheckboxes.val([28,29,30,31])
    $weeklyCheckboxes.val(['MO','TU','WE','TH','FR'])
    $bySetPosition.val(-1)

  }

  refreshNextRunTime()
}

function getWeeklyDateFromValues() {
  let date_from, date_to
  const weeks = Number.parseInt($weeklyDateRangeSelector.val())
  let begin = weekdayLookupMap.get($weeklyPeriodSelector.val())

  if (weeks !== 0) {
    date_from = `midnight ${weeks - 1} week ${begin}`
    date_to = `midnight ${weeks} week ${begin}`
  } else {
    date_from = `midnight -1 week ${begin}`
    date_to = `midnight ${begin}`
  }

  $('#date_from').val(date_from)
  $('#date_to').val(date_to)
  refreshNextRunTime()
}

function getWeeklyRunDay()
{
  const dayOfWeek = $weeklyDaySelector.val();
  $('p.frequency_weekly input[type=checkbox]').val([dayOfWeek])

  refreshNextRunTime()
}

function getDailyDateFromValues () {
  let date_from, date_to
  const days = Number.parseInt($dailyDateRangeSelector.val())
  if (days !== 0) {
    date_from = `midnight ${days} day`
    date_to = `midnight ${days + 1} day`
  } else {
    date_from = 'midnight today'
    date_to = 'midnight tomorrow'
  }

  $('#date_from').val(date_from)
  $('#date_to').val(date_to)
  refreshNextRunTime()
}

function refreshDateToFromValues(schedule)
{
  // $('p.frequency_weekly input[type=checkbox]').val([])
  $('p.frequency_monthly input[type=checkbox]').val([])
  $('p.frequency_yearly input[type=checkbox]').val([])
  $('input#bysetpos').val('')
  $('select#frequency').val(schedule);

  if (schedule === 'Monthly') {
    $('p.frequency_weekly input[type=checkbox]').val([])

    getMonthlyDateFromValues()
    getMonthlyRunDay()
  }
  if (schedule === 'Weekly') {
    getWeeklyDateFromValues()
    getWeeklyRunDay()
  }
  if (schedule === 'Daily') {
    $('p.frequency_weekly input[type=checkbox]').val([])
    getDailyDateFromValues()
  }
}

function reloadSimplifiedScheduleOptions () {
  // First, use the date from/to fields to configure the Data Range and Reporting Period
  let rx
  switch ($frequencySelector.val()) {
    case 'Monthly':
      rx = /midnight (-?\d+) month/g
      const originalMonth = [...$('input#date_from').val().matchAll(rx)][0][1];
      $monthlyDateRangeSelector.val(originalMonth)

      break
    case 'Weekly':
      rx = /midnight (-?\d+) week (mon|tue|wed|thu|fri|sat|sun)/g
      let [,originalWeek,originalSendingDay] = [...$('input#date_from').val().matchAll(rx)][0]
      const week = Number.parseInt(originalWeek) + 1
      $weeklyDateRangeSelector.val(week)
      const sendingDay = getKeyForValueFromMap(weekdayLookupMap, originalSendingDay)
      $weeklyPeriodSelector.val(sendingDay)

      break;
    case 'Daily':
      rx = /midnight (-?\d+) day/g
      const originalDay = [...$('input#date_from').val().matchAll(rx)][0][1];
      $dailyDateRangeSelector.val(originalDay)
      break;
  }

  // Calculate the rest of the schedule options by copying them from the advanced settings
}

function getKeyForValueFromMap(map, value)
{
  return [...map].find(([key, aValue]) => value === aValue)[0]
}
