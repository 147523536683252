import 'jquery-ui'
import {AjaxSync} from "./shared/ajax_utilities";

export default class autocomplete_report {
  #textField
  #idField
  #tbl
  #dataCache

  #operatorField

  #containerClass
  #searchIcon
  #clearIcon

  constructor ($parameterDiv) {
    this.#idField = $parameterDiv.find('input[type=hidden]')
    this.#textField = $parameterDiv.find('input[type=text]')
    this.#tbl = $parameterDiv.data('tbl')

    this.#operatorField = $parameterDiv.parent().find('select#operator_id');

    const html = '<i class="autocomplete-search autocomplete-search-' + this.#tbl + ' icon fa fa-search"></i>' +
      '<i class="btn-autocomplete-clear btn-autocomplete-clear-' + this.#tbl + ' icon fa fa-times"></i>'
    this.#containerClass = 'autocomplete-container'
    this.#textField.after(html).parent().addClass(this.#containerClass)

    this.#searchIcon = $parameterDiv.find(`.autocomplete-search-${this.#tbl}`)
    this.#clearIcon = $parameterDiv.find(`.btn-autocomplete-clear-${this.#tbl}`)

    this.#searchIcon.show()

    this.#dataCache = [];

    this.addHandlers()
  }

  loadCachedData (request, callback) {
    AjaxSync({
      url: app.CACHE.URL_AJAX + this.#tbl + '/get',
      data: {
        tbl_ref: 'reports',
        operator_id: this.#operatorField.val(),
        term: request.term
      },
      method: 'POST'
    }, {
      done: (res) => callback(res.data),
      error: (err) => callback([]),
    });
  }

  handleSelect (event, ui) {
    this.#textField.val(ui.item.label)
    this.#idField.val(ui.item.value)

    this.#searchIcon.hide()
    this.#clearIcon.show()

    return false
  }

  addHandlers () {
    this.#clearIcon.click(() => {
      this.#textField.val('')
      this.#idField.val('')

      this.#searchIcon.show()
      this.#clearIcon.hide()
    })

    this.#textField.autocomplete({
      source: this.loadCachedData.bind(this),
      minLength: 2,
      select: this.handleSelect.bind(this),
      focus: function(e, ui){
        e.preventDefault();

        console.log(e, ui);
      }
    });
  }
}