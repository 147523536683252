import $ from 'jquery'
import LibraryFunctions from '../form/_library_functions'
import { DateTime } from 'luxon'
import autocomplete_report from '../autocomplete_report'
import ScheduleHelpers from './schedule-helpers'
import { map } from 'lodash'
import { zipObject } from 'lodash'
import { merge } from 'lodash'
import {AjaxSync} from "./ajax_utilities";

export function loadReportParameters (report, options, callback) {

  // post data for tpl options
  let postData = {
    tpl_options: {
      report_quick: ( app.OPTIONS.report_quick ) ? '1' : '0'
    }
  };

  AjaxSync({
    url: `${app.CACHE.URL_ADMIN}report/get_params/${report}`,
    method: 'POST',
    data: postData
  }, {
    done: (html) => {
      const $parametersRegion = $('div#parameters_region')
      $parametersRegion.html(html);
      const fieldNames = map($parametersRegion.find('input,select,textarea'), (field) => {
        return $(field).attr('name');
      })
      $('#fields').val(fieldNames.join(','))

      app.DOM.form_input.filter('button#generate_report').prop('disabled', false).removeClass('button-grey').addClass('button-green');

      if (supplied_params) {
        loadPreviousData(callback)
      }
      attachFormControls(options)
    }
  });
}

export function getOperatorReferencePeriod (e) {
  e.preventDefault()

  const operator_id = $('#operator_id').val()
  if (operator_id == '') {
    $('#operator_id').style.border = '1px solid red'
    setTimeout(() => {
      $('#operator_id').style.border = ''
    }, 500)

    return
  }

  AjaxSync({
    url: `${app.CACHE.URL_ADMIN}report/all/get_reference_period/${operator_id}`,
    method: 'GET',
    dataType: 'json',
  }, { done: updateReferencePeriod });
}

export function updateReferencePeriod (data) {
  const start = new Date(data.data[0]),
    end = new Date(data.data[1])

  $('input.date_from').val(formatDateYYYYMMDD(start))
  $('input.date_to').val(formatDateYYYYMMDD(end))
}

export function buildPipedUrlParametersFromNameValuePairs (params) {
  const l = new LibraryFunctions()
  const urlParams = []

  params.forEach((p) => {
    if (p.name.substr(-2) === '[]') {
      if (!urlParams[p.name]) {
        urlParams[p.name] = []
      }
      urlParams[p.name].push(p.value)
    } else {
      urlParams[p.name] = p.value
    }
  })

  return l.combineParams(urlParams)
}

function loadPreviousData (callback) {
  const $reportParamsSection = $('div#parameters_region').find('input,select,textarea').filter((i, el) => {
    const name = $(el).prop('name')
    return supplied_params.hasOwnProperty(name)
  })
  const keys = map($reportParamsSection, (el) => $(el).prop('name'))
  const fields = zipObject(keys, $reportParamsSection)
  fields['date_from'] = $('input#date_from')
  fields['date_to'] = $('input#date_to')
  let current = []

  for (const k in supplied_params) {
    if (!fields.hasOwnProperty(k)) {
      continue
    }

    const $field = $(fields[k])
    if (k.startsWith('date')) {
      let date
      if (/\d{4}.\d{2}.\d{2}/.test(supplied_params[k])) {
        date = DateTime.fromISO(supplied_params[k])
      } else if (/\d{2}.\d{2}.\d{4}/.test(supplied_params[k])) {
        date = DateTime.fromFormat(supplied_params[k], 'dd-MM-yyyy')
      } else {
        date = supplied_params[k]
      }
      $field.val(typeof date === 'string' ? date : date.toFormat('dd-MM-yyyy')).trigger('change')
    } else if (['checkbox', 'radio'].includes($field.prop('type')) || $field[0].tagName === 'select') {
      $field.val([supplied_params[k]]) // For these fields, setting val with an array will select all the options matching it
    } else {
      $field.val(supplied_params[k])
    }

    current.push(k)
  }
  $('input#fields').val(current.join(','))

  if (callback) {
    callback()
  }
}

export function attachFormControls(options) {
  const defaults = {
    datepickers: true,
    tooltips: true,
    autocomplete_report: true,
  }
  options = merge({}, defaults, options)

  if (options.datepickers) {

    let $dateFrom,
        $dateTo,
        $datepickers = app.DOM.form.find('.datePicker');

    let optionsDatepicker = {
      changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
      dateFormat: 'dd-mm-yy',
    };

    if( app.OPTIONS.report_quick ) {
      optionsDatepicker.maxDate = 0;
      optionsDatepicker.minDate = '-1y';
      optionsDatepicker.onSelect = function(dmy){
        if( this.id === 'date_from' ) {
          $dateTo.datepicker('option', 'minDate', dmy);
        } else if( this.id === 'date_to' ) {
          $dateFrom.datepicker('option', 'maxDate', dmy);
        }
      };
    };

    $dateFrom = $datepickers.filter('#date_from').datepicker(optionsDatepicker);
    $dateTo = $datepickers.filter('#date_to').datepicker(optionsDatepicker);
    $datepickers.filter('.datePicker').not('#date_from,#date_to').datepicker(optionsDatepicker);
  }

  if (options.tooltips) {
    enableTooltips()
  }

  // Operator/Depot Selectors
  const $operatorSelector = $('#operator_id');
  const $operatorDepotSelector = $('select#operator_depot');
  if ($operatorSelector.length > 0 && $operatorDepotSelector.length > 0) {
    const operator_id = $operatorSelector.val()
    const operatorTag = $operatorSelector[0].tagName

    let depots
    if (operatorTag === 'SELECT') {
      depots = operators_and_depots[operator_id]
    } else {
      depots = operators_and_depots
    }

    for (const id in depots) {
      const label = depots[id]
      const $option = $('<option>').text(label).val(id)
      $operatorDepotSelector.append($option)
    }

    if (supplied_params && supplied_params.operator_depot) {
      $operatorDepotSelector.val(supplied_params.operator_depot)
    }
  }

  app.DOM.form.find('select[multiple]').multiselect({
    columns: 1,
    selectAll: true,
    search: true,
    placeholder: '- Nothing selected -',
    onOptionClick: function(e){
      // determine if we need to count <option value="0">
      var $el = $(e.element).next(),
          count = $el.find(':checked').not('[value="0"]').length;

      // update text
      $el.closest('.multiselect-container').find('.multiple_count').text(count);
    }
  });

  // add initial counts to multiselects
  app.DOM.form.find('select[multiple]').each(function(k, el){
    let count = $(el).find('option').length;
    $(el).prev().prev().find('.multiple_count_total').text(count);
    $(el).prev().prev().find('.multiple_count').text($(el).val().length);
  });

  // For the WTD report, get the operator reference period information to feed into the date ranges
  $('button#use_reference_period').click(getOperatorReferencePeriod)

  if (options.autocomplete_report) {
    $('div.autocomplete').each(function (i, el) {
      new autocomplete_report($(el))
    });
  }
}